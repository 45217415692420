<template>
    <div style="position: relative;">
        <b-form-group :class="classes" :label="label" :label-for="labelFor">
            <b-form-input :class="ValidatinClasses" :id="inputId" :type="passwordType ? 'password' : 'text'" :value="value"
                :size="inputSize" :placeholder="inputPlaceholder"></b-form-input>
            <img @click="togglePasswordVisibility" style="position: absolute; top: 42px; right: 18px;"
                src="@/assets/zaajira-candidate/assets/icons/eye.svg" alt="">
            <img v-if="!passwordType" @click="togglePasswordVisibility" style="position: absolute; top: 42px; right: 18px;"
                src="@/assets/zaajira-candidate/assets/icons/line.svg" alt="">
        </b-form-group>
    </div>
</template>
  
<script>
export default {
    props: {
        inputId: String,
        inputSize: String,
        inputPlaceholder: String,
        ValidatinClasses: Object,
        classes: String,
        label: String,
        labelFor: String,
        value: String,
    },
    data() {
        return {
            passwordType: true, // Initially, the password is hidden
        };
    },
    methods: {
        togglePasswordVisibility() {
            this.passwordType = !this.passwordType
        },
    },
};
</script>
  