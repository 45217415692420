<template>
    <div class="w-100">
        <div class="p-3">
            <h1 style="opacity: 0.7; font-size: 1.25rem;" class="generic-typography">{{ $gettext("Personal_detail") }}
            </h1>
        </div>
        <div style="position: relative;" class="px-3 mb-4 d-flex justify-content-start align-items-center">
            <b-avatar variant="info" src="@/assets/zaajira-candidate/assets/icons/upload.svg" class="user-avtar">
                <img v-if="profileUrl === null" src="@/assets/zaajira-candidate/assets/bg-images/avtar-image.png"
                    width="100%" height="auto" class="img-fluid" />
                <img v-else :src="`${IMAGEURL}${profileUrl}`" width="100%" height="auto" class="img-fluid" />
            </b-avatar>
            <label for="user-dp">
                <b-form-file @change="uploadProfileImage" style="position: fixed; left: -1000000px;"
                    v-model="profileImage" id="user-dp" accept="image/*"></b-form-file>
                <!-- <input style="position: fixed; left: -1000000px;" type="file" v-model="profileImage" name="" id="user-dp"> -->
                <img src="@/assets/zaajira-candidate/assets/icons/upload.svg" width="25px" height="auto"
                    class="img-fluid avtar-upload" />
            </label>
            <div class="ml-3">
                <p class="h-label m-0">
                    {{ $gettext("Upload_Photo") }}
                </p>

                <p class="h-title m-0">{{ $gettext("only_PNG_JPG_Allowed_up_To_10MB") }}</p>
            </div>
        </div>
        <b-row>
            <b-col cols="12" lg="6" md="12" sm="12">
                <b-form class="customized-form w-100">
                    <ValidationObserver ref="edit_personal_details" tag="div">
                        <div class="col-12">
                            <ValidationProvider rules="required" name="fullName" v-slot="{ errors, classes }">

                                <b-form-input :class="classes" id="FullName" v-model="fullName" size="sm" type="text"
                                    placeholder="Full Name"></b-form-input>

                                <div class="error-msg" :class="classes">
                                    <span>{{ errors[0] ? 'Field Required' : errors[0] }}</span>
                                </div>
                            </ValidationProvider>
                        </div>
                        <b-row class="p-1 px-3">
                            <div class="col-md-6">
                                <ValidationProvider rules="required" name="age" v-slot="{ errors, classes }">
                                    <v-select placeholder="Age" class="form-control" :class="classes" v-model="age"
                                        :options="ageOption"> </v-select>
                                    <div class="error-msg" :class="classes">
                                        <span>{{ errors[0] ? 'Field Required' : errors[0] }}</span>
                                    </div>
                                </ValidationProvider>
                            </div>
                            <div class="col-md-6">
                                <ValidationProvider rules="required" name="gender" v-slot="{ errors, classes }">
                                    <v-select placeholder="Select gender" class="form-control" :class="classes"
                                        v-model="gender" :options="['Female', 'Male', 'Other']"> </v-select>
                                    <div class="error-msg" :class="classes">
                                        <span>{{ errors[0] ? 'Field Required' : errors[0] }}</span>
                                    </div>
                                </ValidationProvider>
                            </div>
                        </b-row>
                        <b-row class="p-1 px-3">
                            <div class="col-md-6">
                                <ValidationProvider
                                    :rules="email !== null && mobile !== null ? mobile !== null && mobile !== '' ? 'required|Phone' : '' : 'required|Phone'"
                                    v-slot="{ errors, classes }">

                                    <b-form-input :class="classes" id="mobile" v-model="mobile" size="sm" type="text"
                                        placeholder="Mobile number (eg. 256123456789 or +256123456789)"></b-form-input>

                                    <div class="error-msg" :class="classes">
                                        <span>{{ errors[0] ? 'Mobile number (eg. 256123456789 or +256123456789)' :
                                            errors[0] }}</span>
                                    </div>
                                </ValidationProvider>
                            </div>
                            <div class="col-md-6">
                                <ValidationProvider
                                    :rules="mobile !== null && email !== null ? email !== null && email !== '' ? 'required|Email' : '' : 'required|Email'"
                                    v-slot="{ errors, classes }">
                                    <!-- <ValidationProvider name="email|" :rules="mobile !== null ? '' : 'required|Email'"
                                    v-slot="{ errors, classes }"> -->

                                    <b-form-input :class="classes" id="email" v-model="email" size="sm" type="text"
                                        placeholder="Email"></b-form-input>

                                    <div class="error-msg" :class="classes">
                                        <span>{{ errors[0] ? 'Field Required' : errors[0] }}</span>
                                    </div>
                                </ValidationProvider>
                            </div>

                        </b-row>
                        <b-row class="p-1 px-3">
                            <div class="col-12">
                                <ValidationProvider rules="required" name="nationality" v-slot="{ errors, classes }">
                                    <v-select placeholder="Nationality" class="form-control" :class="classes"
                                        v-model="nationality" :options="NationalityOption"
                                        :get-option-label="(option) => option.nationality"> </v-select>
                                    <div class="error-msg" :class="classes">
                                        <span>{{ errors[0] ? 'Field Required' : errors[0] }}</span>
                                    </div>
                                </ValidationProvider>
                            </div>
                        </b-row>
                        <b-row class="p-1 px-3">
                            <div class="col-md-6">
                                <ValidationProvider rules="required" name="Country" v-slot="{ errors, classes }">
                                    <v-select placeholder="Country" class="form-control" :class="classes"
                                        v-model="country" :options="CountryOption"
                                        @input="GetAllCityOfCountry(country.country_id)"
                                        :get-option-label="(option) => option.country_name">
                                    </v-select>
                                    <div class="error-msg" :class="classes">
                                        <span>{{ errors[0] ? 'Field Required' : errors[0] }}</span>
                                    </div>
                                </ValidationProvider>
                            </div>
                            <div class="col-md-6">
                                <ValidationProvider rules="required" name="city" v-slot="{ errors, classes }">
                                    <v-select placeholder="City" class="form-control" :class="classes" v-model="city"
                                        :options="CityOption"> </v-select>
                                    <div class="error-msg" :class="classes">
                                        <span>{{ errors[0] ? 'Field Required' : errors[0] }}</span>
                                    </div>
                                </ValidationProvider>
                            </div>
                        </b-row>
                        <div class="col-12 mb-2 btn-flex-fd-row-jc-sb">
                            <b-button @click.prevent.stop="ValidateInput" variant="primary" size="sm">
                                {{ $gettext("Save_change") }}
                            </b-button>
                        </div>

                        <!-- <div class="col-12 mb-4">
                                <b-button @click.prevent.stop="ValidateInput" class="btn full-width-btn" variant="primary"
                                    size="sm">Next</b-button>
                            </div> -->
                    </ValidationObserver>
                </b-form>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import PasswordInput from '../../../components/Password/Password-Input.vue';
import CandidateService from "@/apiServices/CandidateService";
import MasterServices from "@/apiServices/MasterServices";
import avtar from "@/assets/zaajira-candidate/assets/icons/ph_user-bold.svg";
import { IMAGEURL } from "@/config";

export default {
    name: "changePassword",
    components: { PasswordInput },
    data() {
        return {
            avtar,
            IMAGEURL,
            profileImage: [],
            profileUrl: null,
            fullName: null,
            user_first_name: null,
            user_last_name: null,
            age: null,
            gender: null,
            email: null,
            mobile: null,
            nationality: null,
            country: null,
            city: null,
            // ageOption: [],
            CountryOption: [],
            CityOption: [],
            NationalityOption: [],
        }
    },
    beforeMount() {
        this.StoreSelectedIndustry()
        this.PreFilledData()
    },
    methods: {
        async uploadProfileImage(e) {
            console.log('file', e.target.files[0]);
            let form_data = new FormData();
            form_data.append('image', e.target.files[0]);
            let loader = this.$loading.show();
            try {
                const imgResp = await CandidateService.uploadProfileImage(form_data)
                console.log('imgResp', imgResp);
                console.log('imgRespMSG', imgResp.data.data.msg);
                console.log('imgRespPath', imgResp.data.data.path);
                if (imgResp.data.status && imgResp.data.message === 'Image uploaded successfully') {
                    this.profileUrl = imgResp.data.data.path
                    this.$store.dispatch("onSetProfileImage", imgResp.data.data.path);

                    this.$toasted.show(`${imgResp.data.message}`, {
                        duration: 6000,
                        type: "success",
                        icon: "check",
                    });
                    loader.hide();
                }
                else {
                    throw imgResp
                }
            } catch (error) {
                this.$toasted.show(`Image extension not suppoted`, { duration: 6000 });
                loader.hide();
                console.log("error", error);
            }
        },
        removePlus(mobileNumber) {
            return mobileNumber.replace(/\+/g, '');
        },
        ValidateInput() {
            console.log('this.nationality', this.nationality);
            console.log('this.country', this.country);
            console.log('this.city', this.city);
            return this.$refs.edit_personal_details.validate().then((result) => {
                console.log('result', result);
                if (this.mobile === null && this.email === null || this.mobile === '' && this.email === '') {
                    this.$toasted.show("Mobile number or email is required", {
                        duration: 6000
                    });
                    return
                }
                if (result) {


                    let split_name = this.fullName.split(' ');

                    if (split_name.length == 2 || split_name.length == 3) {
                        this.user_first_name = split_name[0],
                            this.user_last_name = split_name[1];
                    }


                    const payload = {
                        user_username: this.fullName,
                        first_name: this.user_first_name,
                        last_name: this.user_last_name,
                        age: this.age,
                        gender: this.gender === 'Female' ? 'F' : 'M',
                        email: this.email,
                        mobile_no: this.removePlus(this.mobile),
                        nationality: this.nationality.nationality_id,
                        location: this.country.country_id,
                        city: this.city.city_id,
                    }
                    console.log(payload);
                    let loader = this.$loading.show();
                    CandidateService.updateUser(payload)
                        .then((res) => {
                            console.log(res);
                            console.log('res', res.data.data);
                            console.log('res', res.data.message);
                            this.$toasted.show(`${res.data.message}`, {
                                duration: 6000,
                                type: "success",
                                icon: "check",
                            });
                            // this.$router.push({ name: "Industry" })
                            loader.hide();
                        })
                        .catch((error) => {
                            loader.hide();
                            console.log(error);
                        })

                }
            })
        },
        async getAllNationality() {
            // MasterServices.GetAllNationality().then((res) => {
            //     this.NationalityOption = res.data.data;
            // });

            try {
                const NationalityRes = await MasterServices.GetAllNationality()
                return NationalityRes.data.data;
            } catch (error) {

            }
        },
        async getAllCountry() {
            // MasterServices.GetAllCountry()
            //     .then((res) => {
            //         console.log("res.data.data", res.data.data);
            //         this.CountryOption = res.data.data.length ? res.data.data : []
            //         console.log("this.CountryOption", this.CountryOption);

            //     })
            //     .catch((error) => {
            //         console.log(error);
            //     })
            try {
                const AllCountryRes = await MasterServices.GetAllCountry()
                return AllCountryRes.data.data
            } catch (error) {
                console.log(error);
            }
        },
        async GetAllCityOfCountry(country_id) {
            console.log("this.country", country_id);
            if (country_id !== null) {
                const payload = {
                    country_id: country_id
                }
                // MasterServices.GetAllCityOfCountry(payload).then((res) => {
                //     console.log('City::', res);
                //     this.CityOption = res.data.data.map((city) => {
                //         return {
                //             label: city.city_name,
                //             city_id: city.city_id,
                //         };
                //     });

                // });
                try {
                    const cityRes = await MasterServices.GetAllCityOfCountry(payload)
                    this.CityOption = cityRes.data.data.map((city) => {
                        return {
                            label: city.city_name,
                            city_id: city.city_id,
                        };
                    });

                } catch (error) {
                    console.log("city::error", error);
                }
            }
        },
        async GetMasterData() {
            this.NationalityOption = await this.getAllNationality()
            this.CountryOption = await this.getAllCountry()

        },
        StoreSelectedIndustry() {
            let loader = this.$loading.show();
            const userData = this.$store.getters.getLoggedUser
            console.log("userData.userId", userData.userId);
            CandidateService.GetselectedIndustry(userData.userId)
                .then((res) => {
                    console.log("GetselectedIndustry", res.data.data.types);
                    const industry = res.data.data.types
                    const speciality_id = industry.map((ind) => ind.speciality_id)
                    const storePayload = {
                        userIndustry: speciality_id
                    }
                    console.log('storePayload', storePayload);
                    this.$store.dispatch("onSetSelectedIndustry", storePayload);
                    loader.hide()
                })
                .catch((error) => {
                    console.log("error", error);
                    loader.hide()
                })

        },
        async PreFilledData() {
            let loader = this.$loading.show();
            await this.GetMasterData()
            // console.log("this.$store.getters.getLoggedUser", this.$store.getters.getLoggedUser);
            const userData = this.$store.getters.getLoggedUser
            CandidateService.getUserByUserID(userData.userId)
                .then(async (res) => {
                    console.log("res>>>", res.data.data[0])
                    await this.GetAllCityOfCountry(res.data.data[0].country_id)
                    this.profileUrl = res.data.data[0].profile_image
                    this.fullName = res.data.data[0].user_username
                    this.age = res.data.data[0].user_age
                    this.gender = res.data.data[0].user_gender
                    this.email = res.data.data[0].user_email
                    this.mobile = res.data.data[0].user_mobile_number
                    this.nationality = this.NationalityOption.filter((n) => n.nationality_id === res.data.data[0].nationality_id)
                    this.country = this.CountryOption.filter((n) => n.country_id === res.data.data[0].country_id)
                    this.city = this.CityOption.filter((n) => n.city_id === res.data.data[0].city_id)
                    loader.hide();
                })
        }
    },
    computed: {
        ageOption() {
            // Generate an array of numbers from 1 to 100
            return Array.from({ length: 54 }, (_, index) => index + 18).map((number) => (number));
        },
    },


}
</script>

<style>
.user-avtar {
    min-width: 8rem;
    min-height: 8rem;
}

.avtar-upload {
    cursor: pointer;
    position: absolute;
    left: 125px;
    bottom: 24px;
    background: rgb(255, 255, 255);
    padding: 3px;
    border-radius: 50px;
}

@media screen and (max-width: 992px) {
    .user-avtar {
        min-width: 5rem;
        min-height: 5rem;
    }

    .avtar-upload {
        cursor: pointer;
        position: absolute;
        left: 82px;
        bottom: 11px;
        background: rgb(255, 255, 255);
        padding: 3px;
        border-radius: 50px;
    }
}
</style>